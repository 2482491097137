@font-face {
  font-family: "SF Pro Display Bold";
  src: url("/assets/fonts/SFPRODISPLAYBOLD.OTF") format("opentype");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display Heavy Italic";
  src: url("/assets/fonts/SFPRODISPLAYHEAVYITALIC.OTF") format("opentype");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display Light Italic";
  src: url("/assets/fonts/SFPRODISPLAYLIGHTITALIC.OTF") format("opentype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display Medium";
  src: url("/assets/fonts/SFPRODISPLAYMEDIUM.OTF") format("opentype");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display Regular";
  src: url("/assets/fonts/SFPRODISPLAYREGULAR.OTF") format("opentype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display Semi Bold Italic";
  src: url("/assets/fonts/SFPRODISPLAYSEMIBOLDITALIC.OTF") format("opentype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display Thin Italic";
  src: url("/assets/fonts/SFPRODISPLAYTHINITALIC.OTF") format("opentype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display Ultra Light Italic";
  src: url("/assets/fonts/SFPRODISPLAYULTRALIGHTITALIC.OTF") format("opentype");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
