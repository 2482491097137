// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$yaberry-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$yaberry-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$yaberry-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$yaberry-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $yaberry-primary,
      accent: $yaberry-accent,
      warn: $yaberry-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($yaberry-theme);

/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "styles/typography";

:root {
  --background: #eae6e6;
  --default-black: #000;
  --default-black-rgb: rgb(0, 0, 0);
  --default-red: #ff3434;

  --font-ultra-light: "SF Pro Display Ultra Light Italic", sans-serif;
  --font-thin-italic: "SF Pro Display Thin Italic", sans-serif;
  --font-semi-bold-italic: "SF Pro Display Semi Bold Italic", sans-serif;
  --font-regular: "SF Pro Display Regular", sans-serif;
  --font-medium: "SF Pro Display Medium", sans-serif;
  --font-light-italic: "SF Pro Display Light Italic", sans-serif;
  --font-heavy-italic: "SF Pro Display Heavy Italic", sans-serif;
  --font-bold: "SF Pro Display Bold", sans-serif;

  /**
    New Design
   */
  --color-purple-100: #f9f5ff;
  --color-purple-200: #eadcff;
  --color-purple-300: #c8a7ff;
  --color-purple-400: #9351ff;
  --color-purple-500: #8134fe;
  --color-purple-600: #5a10d2;

  --color-white: #ffffff;

  --color-gray-100: #f7f5f3;
  --color-gray-200: #ebe8e3;
  --color-gray-300: #d3d0cd;
  --color-gray-500: #8c8686;
  --color-gray-600: #5a5656;

  --color-red-100: #fff5f4;
  --color-red-200: #ffded9;
  --color-red-400: #ff5f49;
  --color-red-300: #ffc2ba;
  --color-red-500: #ef3d24;
}

.ui-background-white {
  background: var(--color-white);
}
.ui-color-gray-500 {
  color: var(--color-gray-500);
}
.ui-color-red-500 {
  color: var(--color-red-500);
}

$font-max-size: 900;
$font-index: 100;
$font-offset: 100;

@while $font-index <= $font-max-size {
  .inter-#{$font-index} {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: #{$font-index};
    font-style: normal;
    font-variation-settings: "slnt" 0;
    font-feature-settings:
      "clig" off,
      "liga" off;
  }
  $font-index: $font-index + $font-offset;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: var(--color-gray-100);
  font-size: 10px;
  font-family: Inter, sans-serif;
}

* {
  outline: none !important;
}

.hide {
  display: none !important;
}

.input {
  --internal-light-dark: transparent !important;
  background: transparent;
  border: none;
  width: 100%;
  height: var(--input-height, 4.6rem);
  font-family: var(--input-font-family, var(--font-bold));
  font-size: var(--input-font-size, 3rem);

  &.error {
    color: var(--default-red);
  }

  &:-internal-autofill-selected {
    font-size: 3rem;
    background-color: transparent !important;
  }

  &:-webkit-autofill {
    font-size: 3rem;
    background-color: transparent !important;
  }

  &[disabled] {
    opacity: 0.5;
  }
}

.font-ultra-light {
  font-family: var(--font-ultra-light);
}
.font-thin-italic {
  font-family: var(--font-thin-italic);
}
.font-semi-bold-italic {
  font-family: var(--font-semi-bold-italic);
}
.font--regular {
  font-family: var(--font-regular);
}
.font-medium {
  font-family: var(--font-medium);
}
.font-light-italic {
  font-family: var(--font-light-italic);
}
.font-heavy-italic {
  font-family: var(--font-heavy-italic);
}
.font-bold {
  font-family: var(--font-bold);
}

.white-color {
  color: #ffffff;
}
.black-color {
  color: var(--default-black);
}
.bg-black {
  background: var(--default-black);
}
.black-half-color {
  color: rgba(0, 0, 0, 0.5);
}
.gray-color {
  color: #908c8c;
}
.green-color {
  color: #24ad0e;
}
.red-color {
  color: #ff0c0c;
}
.yellow-color {
  color: #fbc660;
}
.dark-green-color {
  color: #00a99f;
}
.violet-color {
  color: #af2dff;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
